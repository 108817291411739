import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent, NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AppService } from "../app.service";
import { ToastrService } from 'ngx-toastr';
import { BugsService } from "../_services/bugs.service";

@Component({
  selector: 'app-bug',
  templateUrl: './bug.component.html',
  styleUrls: ['./bug.component.scss',
    '../../../node_modules/@swimlane/ngx-datatable/index.scss',
    '../../vendor/libs/ngx-datatable/ngx-datatable.scss',
    '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css',
  ],
  encapsulation: ViewEncapsulation.None,

})
export class BugComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  // variables
  form = { name: "" };
  validation = true;
  selectedId: any;
  selectedIndex: any;
  forDelete: any;
  allBugs = [];

  // table
  loadingIndicator = true;
  selected = [];
  rows = [];

  constructor(
    private appService: AppService,
    private bugSer: BugsService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    public toastrService: ToastrService,

  ) {
    this.appService.pageTitle = "Bugs";
  }


  ngOnInit() {
    this.loadingIndicator = true;
    this.bugSer.getAll().subscribe((result: any) => {
      if (result['status'] == 'success') {
        this.loadData(result.data);
        this.loadingIndicator = false;
      } else {
        this.showToast("Success", "Something went wrong");
      }
    });
  }

  // filters results
  loadData(data) {
    this.allBugs = data;
    let tempRows = [];
    this.allBugs.forEach((item: any) => {
      tempRows.push({
        title: item.title,
        description: item.description,
        file1: item.file1,
        file2: item.file2,
        status: item.status,
        resolvedAt: item.status == 'active' ? '-' : this.datepipe.transform(item.createdAt, "dd-MM-yyyy"),
        reportedDate: this.datepipe.transform(item.createdAt, "dd-MM-yyyy"),
        id: item.id,
      });
      this.rows = tempRows;
      this.loadingIndicator = false;
    });
  }

  selectId(id) {
    this.selectedId = id;
    this.selectedIndex = this.allBugs.findIndex(d => d.id === this.selectedId);
  }

  doResolve() {
    this.loadingIndicator = true;
    this.bugSer.resolve(this.selectedId).subscribe((result: any) => {
      if (result.status === "success") {
        this.showToast("Success", "Successfully Updated");
        this.modalService.dismissAll("");
        this.allBugs[this.selectedIndex].status = 'resolved';
        this.loadData(this.allBugs);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  deviceInfo(type) {
    let temp = JSON.parse(this.allBugs[this.selectedIndex].deviceInfo);
    let s;
    switch (type) {
      case 'id':
        if (temp.id === undefined) {
          s = false;
        }else{
          s = true;
        }
        break;
      case 'name':
        s = temp.name;
        break;
      case 'model':
        s = temp.model;
        break;
      case 'systemName':
        s = temp.systemName;
        break;
      case 'irelease':
        s = temp.utsname.release;
        break;
      case 'version':
        s = temp.utsname.version;
      case 'machine':
        s = temp.utsname.machine;
        break;
      case 'sysname':
        s = temp.utsname.sysname;
        break;
      case 'sdkInt':
        s = temp.version.sdkInt;
        break;
      case 'release':
        s = temp.version.release;
        break;
      case 'previewSdkInt':
        s = temp.version.previewSdkInt;
        break;
      case 'isPhysicalDevice':
        s = temp.isPhysicalDevice;
    }
    return s;
  }

  //
  // Bootstrap Modals
  //
  open(content, options = {}) {
    this.modalService.open(content, options);
  }
  // Table
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allBugs.filter((d) => {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }


  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}


