import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-greek-news',
  templateUrl: './greek-news.component.html',
  styleUrls: ['./greek-news.component.scss']
})
export class GreekNewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
