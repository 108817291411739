import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-greek-business',
  templateUrl: './greek-business.component.html',
  styleUrls: ['./greek-business.component.scss']
})
export class GreekBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
