import {
  Component,
  OnInit
} from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../app.service";
import { AuthService } from './../_services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    "../../vendor/styles/pages/authentication.scss",
    './login.component.scss',
  ]
})
export class LoginComponent implements OnInit {

  form = {
    email: "",
    password: "",
    rememberMe: false,
  };
  validation = true;
  inValidcredentials = false;

  constructor(private appService: AppService,
    private router: Router,
    public toastrService: ToastrService,
    private authSer: AuthService,) {
    this.appService.pageTitle = "Login";
  }

  login() {
    if (this.form.email === "" || this.form.password === "") {
      this.validation = false;
    } else {
      this.validation = true;
      this.doLogin();
    }
  }
  doLogin() {
    this.authSer.login(this.form).subscribe((result: any) => {
      if (result["status"] == "success") {
        this.inValidcredentials = false;
        var temp = result["data"]["admin"];
        temp.password = '';
        localStorage.setItem("login", JSON.stringify(result["data"]["admin"]));
        localStorage.setItem("token", JSON.stringify(result["data"]["token"]));
        this.router.navigate(["dashboard"]);
      } else {
        this.showToast("Success", "Please enter a valid Email and Password");
        this.inValidcredentials = true;
      }
    });
  }
  ngOnInit() {
    if (localStorage.getItem("login") != null) {
      this.router.navigate(["dashboard"]);
    }
  }
  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}
