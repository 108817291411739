import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent } from "./not-found/not-found.component";

// *******************************************************************************
// Layouts

import { Layout1Component } from "./layout/layout-1/layout-1.component";

// *******************************************************************************
// Pages

import { AuthGuard } from "./_guard/auth.guard";
import { LoginComponent } from "./login/login.component";
import { LayoutBlankComponent } from "./layout/layout-blank/layout-blank.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { Layout2Component } from "./layout/layout-2/layout-2.component";
import { ProfileComponent } from "./profile/profile.component";
import { UserAllComponent } from "./users/user-all/user-all.component";
import { UserViewComponent } from "./users/user-view/user-view.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { TheYardComponent } from "./the-yard/the-yard.component";
import { ConnectionsComponent } from "./connections/connections.component";
import { D9TvComponent } from "./d9-tv/d9-tv.component";
import { GreekNewsComponent } from "./greek-news/greek-news.component";
import { VendorsComponent } from "./vendors/vendors.component";
import { ProductsComponent } from "./products/products.component";
import { GreekBusinessComponent } from "./greek-business/greek-business.component";
import { FeaturedListRequestComponent } from "./featured-list-request/featured-list-request.component";
import { ChatsComponent } from "./chats/chats.component";
import { GroupChatsComponent } from "./group-chats/group-chats.component";
import { OrganizationsComponent } from "./organizations/organizations.component";
import { UniversityComponent } from "./university/university.component";
import { CityComponent } from "./city/city.component";
import { AlumniCityComponent } from "./alumni-city/alumni-city.component";
import { ProfessionComponent } from "./profession/profession.component";
import { GoverningBodyComponent } from "./governing-body/governing-body.component";
import { VerificationRequestComponent } from "./verification-request/verification-request.component";
import { EmailsComponent } from "./emails/emails.component";
import { StatsAndMatricsComponent } from "./stats-and-matrics/stats-and-matrics.component";
import { SettingComponent } from "./setting/setting.component";
import { BugComponent } from "./bug/bug.component";
import { ReportComponent } from "./report/report.component";
import { MembershipDuesComponent } from "./membership-dues/membership-dues.component";

// *******************************************************************************
// Routes

const routes: Routes = [
  {
    path: "",
    component: LayoutBlankComponent,
    pathMatch: "full",
    children: [{ path: "", component: LoginComponent }],
  },
  {
    path: "",
    component: Layout1Component,
    canActivateChild: [AuthGuard],
    children: [
      { path: "dashboard", component: DashboardComponent },
      { path: "notifications", component: NotificationsComponent },
      { path: "users", component: UserAllComponent },
      { path: "users/view", component: UserViewComponent },
      { path: "the-yard", component: TheYardComponent },
      { path: "connections", component: ConnectionsComponent },
      { path: "d9-tv", component: D9TvComponent },
      { path: "greek-news", component: GreekNewsComponent },
      { path: "vendors", component: VendorsComponent },
      { path: "products", component: ProductsComponent },
      { path: "breek-business", component: GreekBusinessComponent },
      { path: "featured-listing-request", component: FeaturedListRequestComponent },
      { path: "chat", component: ChatsComponent },
      { path: "group-chat", component: GroupChatsComponent },
      { path: "organizations", component: OrganizationsComponent },
      { path: "universities", component: UniversityComponent },
      { path: "city", component: CityComponent },
      { path: "alumni-city", component: AlumniCityComponent },
      { path: "profession", component: ProfessionComponent },
      { path: "governing-body", component: GoverningBodyComponent },
      { path: "verification-request", component: VerificationRequestComponent },
      { path: "emails", component: EmailsComponent },
      { path: "stats-and-metrics", component: StatsAndMatricsComponent },
      { path: "setting", component: SettingComponent },
      { path: "profile", component: ProfileComponent },
      { path: "bugs", component: BugComponent },
      { path: "reports", component: ReportComponent },
      { path: "membership-dues", component: MembershipDuesComponent },
    ],
  },

  // 404 Not Found page
  { path: "**", component: NotFoundComponent },
];

// *******************************************************************************
//

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: "legacy",
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
