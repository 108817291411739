import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private router: Router) { }

  CurrentToken: any;
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage.getItem("token") != null) {
      this.CurrentToken = JSON.parse(localStorage.getItem("token"));
      let token = req.clone({
        setHeaders: {
          accesstoken: this.CurrentToken,
        },
      });

      return next.handle(token).pipe(
        tap(
          (event) => { },
          (err) => {
            if (err instanceof HttpErrorResponse && err.status == 401) {
              localStorage.clear();
              this.router.navigate([""]);
            }
          }
        )
      );
    } else {
      return next.handle(req).pipe(
        tap(
          (event) => { },
          (err) => {
            if (err instanceof HttpErrorResponse && err.status == 401) {
              localStorage.clear();
              this.router.navigate([""]);
            }
          }
        )
      );
    }
  }
}
