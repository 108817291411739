import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AppService } from "../app.service";
import { SettingService } from "../_services/setting.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: [
    "./setting.component.scss",
    "../../vendor/libs/ngx-toastr/ngx-toastr.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SettingComponent implements OnInit {
  userData: any;
  userId: any;
  emptyPassword: boolean = false;
  unmatchedPassword: boolean = false;
  password: any;
  repeatPassword: any;
  form = {
    fullName: "",
    email: "",
    avatar: "",
  };
  solidForm = {
    fullName: "",
    email: "",
    avatar: "",
  };

  constructor(
    private appService: AppService,
    private settingSer: SettingService,
    private modalService: NgbModal,
    public toastrService: ToastrService
  ) {
    this.appService.pageTitle = "Settings";
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("login"));
    this.userId = this.userData.id;
    this.form.fullName = this.userData.fullName;
    this.form.email = this.userData.email;
    this.form.avatar = this.userData.avatar;


    this.solidForm.fullName = this.userData.fullName;
    this.solidForm.email = this.userData.email;
    this.solidForm.avatar = this.userData.avatar;
  }
  updateProfile() {
    if (((this.form.fullName != this.solidForm.fullName) || (this.form.email != this.solidForm.email) || (this.form.fullName != this.solidForm.fullName) && (this.form.email != this.solidForm.email))) {
      let data = {
        "id": this.userId,
        "fullName": this.form.fullName,
        "email": this.form.email,
      };
      this.settingSer.updateProfile(data).subscribe((result: any) => {
        if (result.status === "success") {

          localStorage.setItem("login", JSON.stringify(result.data));
          this.showToast("Success", "Successfully Updated");
        } else {
          this.showToast("Success", "Something went wrong");
        }
      })
    }
    // this.showToast('warning', "Disabled", "Feature disabled by dev");
  }

  resetPassword() {
    if (this.password != null) {
      this.emptyPassword = false;
      if (this.password == this.repeatPassword) {
        this.unmatchedPassword = false;
        this.emptyPassword = false;
        let data = {
          id: this.userId,
          password: this.password
        }
        this.settingSer.changePassword(data).subscribe((result: any) => {
          if (result.status === "success") {
            this.showToast("Success", "Successfully Updated");
            this.modalService.dismissAll("");
          } else {
            this.showToast("Success", "Something went wrong");
          }
        });
      } else {
        this.unmatchedPassword = true;
        this.emptyPassword = false;
      }
    } else {
      this.emptyPassword = true;
      this.unmatchedPassword = false;
    }
  }
  open(content, options = {}) {
    this.modalService.open(content, options);
  }
  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}
