import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent, NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AppService } from "../app.service";
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from "../_services/reports.service";

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss',
    '../../../node_modules/@swimlane/ngx-datatable/index.scss',
    '../../vendor/libs/ngx-datatable/ngx-datatable.scss',
    '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css',
  ],
  encapsulation: ViewEncapsulation.None,

})
export class ReportComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  // variables
  form = { name: "" };
  validation = true;
  selectedId: any;
  selectedIndex: any;
  forDelete: any;
  allReports = [];

  // table
  loadingIndicator = true;
  selected = [];
  rows = [];

  constructor(
    private appService: AppService,
    private repSer: ReportsService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    public toastrService: ToastrService,

  ) {
    this.appService.pageTitle = "Reports";
  }


  ngOnInit() {
    this.loadingIndicator = true;
    this.repSer.getAll().subscribe((result: any) => {
      if (result['status'] == 'success') {
        this.loadData(result.data);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  // filters results
  loadData(data) {
    this.allReports = data;
    if (this.allReports.length == 0) {
      this.rows = [];
    }
    let tempRows = [];
    this.allReports.forEach((item: any) => {
      tempRows.push({
        text: item.text == '' ? '-' : item.text,
        postHeading: item.post.heading == '' ? '-' : item.post.heading,
        postCaption: item.post.caption == '' ? '-' : item.post.caption,
        postLink: item.post.link == '' ? '-' : item.post.link,
        postFile: item.post.file,
        status: item.status,
        postThumbnail: item.post.thumbnail,
        reportedBy: item.user.email,
        reportedAt: this.datepipe.transform(item.createdAt, "dd-MM-yyyy"),
        id: item.id,
      });
      this.rows = tempRows;
      this.loadingIndicator = false;
    });
  }

  selectedButton(id) {
    var tempIndex = this.allReports.findIndex(d => d.id === id);
    if (this.rows[tempIndex].status == 'active') {
      return true;
    } else {
      return false;
    }

  }

  selectId(id) {
    this.selectedId = id;
    this.selectedIndex = this.allReports.findIndex(d => d.id === this.selectedId);
  }

  blockPost() {
    this.loadingIndicator = true;
    this.allReports[this.selectedIndex].status = 'block';
    let temp = {
      'id': this.allReports[this.selectedIndex].id,
      "postId": this.allReports[this.selectedIndex].postId
    }
    this.repSer.blockPost(temp).subscribe((result: any) => {
      if (result.status === "success") {
        this.allReports[this.selectedIndex].status = 'block';
        this.modalService.dismissAll("");
        this.loadData(this.allReports);
        this.showToast("Success", "Successfully Updated");
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  deviceInfo(type) {
    let temp = JSON.parse(this.allReports[this.selectedIndex].deviceInfo);
    let s = '';
    switch (type) {
      case 'model':
        s = temp.model;
        break;
      case 'sdkInt':
        s = temp.version.sdkInt;
        break;
      case 'release':
        s = temp.version.release;
        break;
      case 'previewSdkInt':
        s = temp.version.previewSdkInt;
        break;
      case 'isPhysicalDevice':
        s = temp.isPhysicalDevice;
    }
    return s;
  }

  //
  // Bootstrap Modals
  //
  open(content, options = {}) {
    this.modalService.open(content, options);
  }
  // Table
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allReports.filter((d) => {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }


  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }

}
