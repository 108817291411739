import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UploaderService {
  private url = environment.baseUrl;
  constructor(private http: HttpClient, private router: Router) {}

  uploader(data,folder) {
    const fd = new FormData();
    fd.append('file', data);
    fd.append('folder', folder);
    return this.http.post(this.url + "imageUploader" , fd);
  }
  videoUploader(data,folder) {
    const fd = new FormData();
    fd.append('file', data);
    fd.append('folder', folder);
    return this.http.post(this.url + "videoUploader" , fd);
  }
}
