import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class D9TvService {
  private url = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }

  getAll() {
    return this.http.post(this.url + "d9tv/getAll", { 'data': null });
  }
  getComments(postId) {
    return this.http.post(this.url + "d9tv/getComments", { 'postId': postId });
  }
  create(data) {
    return this.http.post(this.url + "d9tv/create", data);
  }
  update(data) {
    return this.http.post(this.url + "d9tv/update/", data);
  }
  deleteComment(data) {
    return this.http.post(this.url + "d9tv/deleteComment/", data);
  }
  delete(id) {
    return this.http.post(this.url + "d9tv/delete/", { 'id': id });
  }
}
