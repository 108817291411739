import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-list-request',
  templateUrl: './featured-list-request.component.html',
  styleUrls: ['./featured-list-request.component.scss']
})
export class FeaturedListRequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
