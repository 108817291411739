import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  DatatableComponent,
  NgxDatatableModule,
} from "@swimlane/ngx-datatable";
import { ToastrService } from 'ngx-toastr';
import { UsersService } from "src/app/_services/users.service";
import { environment } from "src/environments/environment";
import { AppService } from "../app.service";
import { UniversityService } from "../_services/university.service";

@Component({
  selector: 'app-university',
  templateUrl: './university.component.html',
  styleUrls: ['./university.component.scss',
    "../../../node_modules/@swimlane/ngx-datatable/index.scss",
    "../../vendor/libs/ngx-datatable/ngx-datatable.scss",
    "../../../node_modules/@swimlane/ngx-datatable/assets/icons.css",
    '../../vendor/libs/ngx-toastr/ngx-toastr.scss'
  ],
  encapsulation: ViewEncapsulation.None,

})
export class UniversityComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  // variables
  private url = environment.baseUrl;
  form = { name: "" };

  validation = true;
  forUpdata: any;
  forDelete: any;
  allUniversity = [];

  // table
  loadingIndicator = true;
  selected = [];
  rows = [];


  constructor(
    private appService: AppService,
    private universitySer: UniversityService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    public toastrService: ToastrService,

  ) {
    this.appService.pageTitle = "University";
  }


  ngOnInit() {
    this.universitySer.getAll().subscribe((result: any) => {
      this.loadData(result.data);
    });

  }

  // filters results
  loadData(data) {
    this.loadingIndicator = true;
    this.allUniversity = this.allUniversity.concat(data);
    this.loadingIndicator = false;
    let tempRows = [];
    this.allUniversity.forEach((item: any) => {
      tempRows.push({
        name: item.name,
        updatedAt: this.datepipe.transform(item.updatedAt, "dd-MM-yyyy"),
        id: item.id,
      });
      this.rows = tempRows;
    });
  }

  create() {
    if (this.form.name === "") {
      this.validation = false;
    } else {
      this.validation = true;
      this.universitySer.create(this.form).subscribe((result: any) => {
        if (result.status === "success") {
          this.showToast("Success", "Successfully Created");
          this.modalService.dismissAll("");
          var temp = [];
          temp.push(result.data);
          console.log(temp);
          this.loadData(temp);
        } else {
          this.showToast("Success", "Something went wrong");

        }
      });
    }
  }


  _update(id) {
    this.forUpdata = id;
    let index = this.allUniversity.findIndex(d => d.id === this.forUpdata);
    this.form.name = this.allUniversity[index].name;
  }
  doUpdate() {
    if (this.form.name === "") {
      this.validation = false;
    } else {
      this.validation = true;
      this.universitySer.update(this.form, this.forUpdata).subscribe((result: any) => {
        if (result.status === "success") {
          this.showToast("Success", "Successfully Updated");
          this.modalService.dismissAll("");
          let index = this.allUniversity.findIndex(d => d.id === this.forUpdata);
          this.allUniversity[index].name = this.form.name;
          let tempRows = [];
          this.allUniversity.forEach((item: any) => {
            tempRows.push({
              name: item.name,
              updatedAt: this.datepipe.transform(item.updatedAt, "dd-MM-yyyy"),
              id: item.id,
            });
            this.rows = tempRows;
          });
        } else {
          this.showToast("Success", "Something went wrong");
        }
      });
    }
  }





  _delete(id) {
    this.forDelete = id;
  }

  doDelete() {
    this.loadingIndicator = true;
    this.universitySer.delete(this.forDelete).subscribe((result: any) => {
      if (result.status === "success") {
        this.showToast("Success", "Successfully Deleted");
        let index = this.allUniversity.findIndex(d => d.id === this.forDelete);
        this.allUniversity.splice(index, 1);
        this.loadingIndicator = false;
        if (this.allUniversity.length == 0) {
          this.rows=[];
        }
        let tempRows = [];
        this.allUniversity.forEach((item: any) => {
          tempRows.push({
            name: item.name,
            updatedAt: this.datepipe.transform(item.updatedAt, "dd-MM-yyyy"),
            id: item.id,
          });
          this.rows = tempRows;
        });
      } else {
        this.showToast("Success", "Something went wrong");
      }
    });
  }

  //
  // Bootstrap Modals
  //
  open(content, options = {}) {
    this.modalService.open(content, options);
  }
  // Table
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allUniversity.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }


  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}
