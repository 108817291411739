import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from '../app.service';
import { SettingService } from '../_services/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',
    '../../vendor/libs/ngx-toastr/ngx-toastr.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  // Variables
  userData: any;
  data: any;
  constructor(private appService: AppService,
    private settingSer: SettingService,
    public toastrService: ToastrService,
  ) {
    this.appService.pageTitle = 'Dashboard';
    this.userData = JSON.parse(localStorage.getItem("login"));

  }

  ngOnInit() {
    this.loadCounters();
  }

  loadCounters() {

    this.settingSer.getDashboard().subscribe((result: any) => {
      if (result['status'] == 'success') {
        this.data = result;
      } else {
        this.showToast("Success", "Something went wrong");
      }
    });

  }

  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}
