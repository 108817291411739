import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private url = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) {}

  getAll(org = "all") {
    return this.http.get(this.url + "user/getAll?org=" + org);
  }
  getUserInfoById(id) {
    return this.http.post(this.url + "user/getUserInfoById/", { id: id });
  }
  create(data) {
    return this.http.post(this.url + "user/create", data);
  }
  update(data) {
    return this.http.post(this.url + "user/update/", data);
  }
  statusUpdate(data) {
    return this.http.post(this.url + "user/block/", data);
  }
  verificationUpdate(data) {
    return this.http.post(this.url + "user/verify/", data);
  }
  paymentUpdate(data) {
    return this.http.post(this.url + "user/payment/", data);
  }
  ageUpdate(data) {
    return this.http.post(this.url + "user/age/", data);
  }
  delete(id) {
    return this.http.post(this.url + "user/delete/", id);
  }
  getAllLists() {
    return this.http.post(this.url + "user/getAllLists", { data: null });
  }
}
