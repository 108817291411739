import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgbModal, ModalDismissReasons, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent, NgxDatatableModule } from "@swimlane/ngx-datatable";
import { UsersService } from "src/app/_services/users.service";
import { environment } from "src/environments/environment";
import { AppService } from "../../app.service";
import { UploaderService } from "../../_services/uploader.service";
import { ToastrService } from "ngx-toastr";
import { FormsModule } from "@angular/forms";
import { setInterval } from "../../../../node_modules/timers-browserify";
const now = new Date();

@Component({
  selector: "app-user-all",
  templateUrl: "./user-all.component.html",
  styleUrls: [
    "./user-all.component.scss",
    "../../../../node_modules/@swimlane/ngx-datatable/index.scss",
    "../../../vendor/libs/ngx-datatable/ngx-datatable.scss",
    "../../../../node_modules/@swimlane/ngx-datatable/assets/icons.css",
    "../../../vendor/libs/ngx-toastr/ngx-toastr.scss",
    "../../../vendor/libs/ngx-contextmenu/ngx-contextmenu.scss",
    "../../../vendor/libs/angular-2-dropdown-multiselect/angular-2-dropdown-multiselect.scss",
    "../../../vendor/libs/ng-select/ng-select.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UserAllComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  checkerRunning = true;
  intervalId;

  constructor(
    private appService: AppService,
    private userSer: UsersService,
    private uploaderSer: UploaderService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    public toastrService: ToastrService
  ) {
    this.appService.pageTitle = "Users";
  }

  // variables
  private url = environment.baseUrl;
  universities = [];
  cities = [];
  supporterCheck = false;
  createSupporterCheck = false;
  createFormSteps = 1;
  form = {
    organization: {
      logo: "",
      color: "",
      organization: null,
      universityId: 0,
      college: "",
      initiatedChapter1: null,
      initiatedChapter2: null,
      initiatedChapter3: null,
      seasonInitiated: null,
      yearInitiated: null,
    },
    memberproof: {
      idImage: "",
      membershipImage: "",
      organization: null,
      universityId: 0,
      initiatedChapter1: null,
      initiatedChapter2: null,
      initiatedChapter3: null,
      seasonInitiated: null,
      yearInitiated: null,
    },
    email: "",
    password: "",
    role: "",
    firstName: "",
    lastName: "",
    avatar: "",
    banner: "",
    dob: "",
    cityName: "",
    cityId: 0,
    relationshipStatus: null,
    isPaid: false,
    isVerified: false,
    isAnonymous: false,
    token: "",
    paymentError: "",
    paymentProductID: "",
    paymentPurchaseID: "",
    paymentTransactionDate: "",
    paymentLocalVerificationData: "",
    paymentServerVerificationData: "",
    paymentSource: "",
    education: {
      underGraduateSchool: "",
      graduateSchool: "",
      underGraduateSchoolId: 0,
      graduateSchoolId: 0,
      classification: null,
      organization: null,
      seasonMemberSince: null,
      yearMemberSince: null,
      membershipStatus: null,
      initiatedChapter1: null,
      initiatedChapter2: null,
      initiatedChapter3: null,
      initiatedChapter: "",
      currentChapter: "",
    },
    employment: {
      status: null,
      companyName: "",
      position: "",
    },
    mentorship: {
      status: null,
      details: "",
    },
    business: {
      name: "",
      details: "",
      website: "",
    },
    link: {
      website: "",
      mobileNumber: "",
      email: "",
      facebook: "",
      twitter: "",
      linkedIn: "",
      instagram: "",
      tiktok: "",
    },
  };
  updateForm: any;

  emailCheck = false;
  orgNameCheck = false;

  exist = false;
  forUpdata: any;
  selectedId: any;
  selectedIndex: any;
  allUser = [];

  _fileSelected1 = false;
  _fileSelected2 = false;
  _fileSelected3 = false;
  _fileSelected4 = false;
  image1Check = false;
  image2Check = false;
  image3Check = false;
  image4Check = false;
  image1: File = null;
  image2: File = null;
  image3: File = null;
  image4: File = null;

  reloadPage(value) {
    this.loadData(value);
  }

  imageSave(event, num) {
    if (num == 1) {
      this.image1 = <File>event.target.files[0];
      if (this.image1 != null) {
        if (this.image1["type"].split("/")[0] == "image") {
          this._fileSelected1 = true;
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.updateForm.avatar = event.target.result;
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.image1 = null;
          this.image1Check = true;
          this._fileSelected1 = false;
        }
      } else {
        this._fileSelected1 = false;
      }
    }
    if (num == 2) {
      this.image2 = <File>event.target.files[0];
      if (this.image2 != null) {
        if (this.image2["type"].split("/")[0] == "image") {
          this._fileSelected2 = true;
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.updateForm.memberproof.idImage = event.target.result;
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.image2 = null;
          this.image2Check = true;
          this._fileSelected2 = false;
        }
      } else {
        this._fileSelected2 = false;
      }
    }
    if (num == 3) {
      this.image3 = <File>event.target.files[0];
      if (this.image3 != null) {
        if (this.image3["type"].split("/")[0] == "image") {
          this._fileSelected3 = true;
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.updateForm.memberproof.membershipImage = event.target.result;
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.image3 = null;
          this.image3Check = true;
          this._fileSelected3 = false;
        }
      } else {
        this._fileSelected3 = false;
      }
    }
    if (num == 4) {
      this.image4 = <File>event.target.files[0];
      if (this.image4 != null) {
        if (this.image4["type"].split("/")[0] == "image") {
          this._fileSelected4 = true;
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.updateForm.banner = event.target.result;
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.image4 = null;
          this.image4Check = true;
          this._fileSelected4 = false;
        }
      } else {
        this._fileSelected4 = false;
      }
    }
  }

  step1() {
    this.createFormSteps = 1;
    this.form = {
      organization: {
        logo: "",
        color: "",
        organization: null,
        universityId: 0,
        college: "",
        initiatedChapter1: null,
        initiatedChapter2: null,
        initiatedChapter3: null,
        seasonInitiated: null,
        yearInitiated: null,
      },
      memberproof: {
        idImage: "",
        membershipImage: "",
        organization: null,
        universityId: 0,
        initiatedChapter1: null,
        initiatedChapter2: null,
        initiatedChapter3: null,
        seasonInitiated: null,
        yearInitiated: null,
      },
      email: "",
      password: "",
      role: "",
      firstName: "",
      lastName: "",
      avatar: "",
      banner: "",
      dob: "",
      cityName: "",
      cityId: 0,
      relationshipStatus: null,
      isPaid: false,
      isVerified: false,
      isAnonymous: false,
      token: "",
      paymentError: "",
      paymentProductID: "",
      paymentPurchaseID: "",
      paymentTransactionDate: "",
      paymentLocalVerificationData: "",
      paymentServerVerificationData: "",
      paymentSource: "",
      education: {
        underGraduateSchool: "",
        graduateSchool: "",
        underGraduateSchoolId: 0,
        graduateSchoolId: 0,
        classification: null,
        organization: null,
        seasonMemberSince: null,
        yearMemberSince: null,
        membershipStatus: null,
        initiatedChapter1: null,
        initiatedChapter2: null,
        initiatedChapter3: null,
        initiatedChapter: "",
        currentChapter: "",
      },
      employment: {
        status: null,
        companyName: "",
        position: "",
      },
      mentorship: {
        status: null,
        details: "",
      },
      business: {
        name: "",
        details: "",
        website: "",
      },
      link: {
        website: "",
        mobileNumber: "",
        email: "",
        facebook: "",
        twitter: "",
        linkedIn: "",
        instagram: "",
        tiktok: "",
      },
    };
  }
  _createFileSelected1 = false;
  _createFileSelected2 = false;
  _createFileSelected3 = false;
  _createFileSelected4 = false;
  createImage1Check = false;
  createImage2Check = false;
  createImage3Check = false;
  createImage4Check = false;
  createImage1: File = null;
  createImage2: File = null;
  createImage3: File = null;
  createImage4: File = null;
  createImageSave(event, num) {
    if (num == 1) {
      this.createImage1 = <File>event.target.files[0];
      if (this.createImage1 != null) {
        if (this.createImage1["type"].split("/")[0] == "image") {
          this._createFileSelected1 = true;
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.form.avatar = event.target.result;
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.createImage1 = null;
          this.createImage1Check = true;
          this._createFileSelected1 = false;
        }
      } else {
        this._createFileSelected1 = false;
      }
    }
    if (num == 2) {
      this.createImage2 = <File>event.target.files[0];
      if (this.createImage2 != null) {
        if (this.createImage2["type"].split("/")[0] == "image") {
          this._createFileSelected2 = true;
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.form.banner = event.target.result;
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.createImage2 = null;
          this.createImage2Check = true;
          this._createFileSelected2 = false;
        }
      } else {
        this._createFileSelected2 = false;
      }
    }
    if (num == 3) {
      this.createImage3 = <File>event.target.files[0];
      if (this.createImage3 != null) {
        if (this.createImage3["type"].split("/")[0] == "image") {
          this._createFileSelected3 = true;
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.form.memberproof.membershipImage = event.target.result;
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.createImage3 = null;
          this.createImage3Check = true;
          this._createFileSelected3 = false;
        }
      } else {
        this._createFileSelected3 = false;
      }
    }
    if (num == 4) {
      this.createImage4 = <File>event.target.files[0];
      if (this.createImage4 != null) {
        if (this.createImage4["type"].split("/")[0] == "image") {
          this._createFileSelected4 = true;
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.form.memberproof.idImage = event.target.result;
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.createImage4 = null;
          this.createImage4Check = true;
          this._createFileSelected4 = false;
        }
      } else {
        this._createFileSelected4 = false;
      }
    }
  }

  OrganizationList = [
    {
      name: "Alpha Phi Alpha",
      logo: "Alpha Phi Alpha.png",
      color: "000000",
    },
    {
      name: "Alpha Kappa Alpha",
      logo: "Alpha Kappa Alpha.png",
      color: "DDABC7",
    },
    {
      name: "Kappa Alpha Psi",
      logo: "Kappa Alpha Psi.png",
      color: "9A0000",
    },
    {
      name: "Omega Psi Phi",
      logo: "Omega Psi Phi.png",
      color: "3C0651",
    },
    {
      name: "Delta Sigma Theta",
      logo: "Delta Sigma Theta.png",
      color: "DF0000",
    },
    {
      name: "Phi Beta Sigma",
      logo: "Phi Beta Sigma.png",
      color: "1C3DC8",
    },
    {
      name: "Zeta Phi Beta",
      logo: "Zeta Phi Beta.png",
      color: "1C3DC8",
    },
    {
      name: "Sigma Gamma Rho",
      logo: "Sigma Gamma Rho.png",
      color: "FFBE00",
    },
    {
      name: "Iota Phi Theta",
      logo: "Iota Phi Theta.png",
      color: "663201",
    },
  ];
  chaptersList = [
    "",
    "Alpha",
    "Beta",
    "Gamma",
    "Delta",
    "Epsilon",
    "Zeta",
    "Eta",
    "Theta",
    "Iota",
    "Kappa",
    "Lambda",
    "Mu",
    "Nu",
    "Xi",
    "Omicron",
    "Pi",
    "Rho",
    "Sigma",
    "Tau",
    "Upsilon",
    "Phi",
    "Chi",
    "Psi",
    "Omega",
  ];
  loadingIndicator = true;
  filterType ="email"
  temp = [];
  selected = [];
  rows = [];
  selectedIdRows = [];
  selectedIdRowsForUpdates = [];
  yearsList = [];

  ngOnInit() {
    this.loadData();
    this.getAllLists();
    this.yearListGenerator();
  }

  yearListGenerator() {
    for (let i = now.getFullYear() + 1; i > 1000; i--) {
      let index = i.toString();
      this.yearsList.push(index);
    }
  }

  //
  // ng-select
  //

  selectDisabled = false;
  singleSelectValue: any;

  loadData(org = "all") {
    this.loadingIndicator = true;
    this.userSer.getAll(org).subscribe((result: any) => {
      if (result.status === "success") {
        this.allUser = result.data;
        this.setter(this.allUser);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }
  getUserInfoById() {
    this.loadingIndicator = true;
    this.userSer.getUserInfoById(this.selectedId).subscribe((result: any) => {
      if (result.status === "success") {
        this.selectedIdRows = result.data;
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }
  getAllLists() {
    this.loadingIndicator = true;
    this.userSer.getAllLists().subscribe((result: any) => {
      if (result.status === "success") {
        this.universities = result.data.universities;
        this.cities = result.data.cities;
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  setter(data) {
    let tempRows = [];
    data.forEach((item: any) => {
      tempRows.push({
        avatar: item.avatar == "" ? "assets/avatar.png" : item.avatar,
        name: item.username,
        firstName: item.firstName,
        lastName: item.lastName,
        state: item.state,
        relationshipStatus: item.relationshipStatus,
        email: item.email,
        phone: item.phone_number,
        dob: this.datepipe.transform(item.dob),
        city: item.cityName,
        role: item.role,
        status: item.status,
        paid: item.isPaid,
        age: item.isOldUser,
        verified: item.isVerified,
        createdAt: this.datepipe.transform(item.createdAt, "dd-MM-yyyy"),
        id: item.id,
      });
      this.rows = tempRows;
    });
  }

  create() {
    this.modalService.dismissAll("");
    // if (this.form.email === "" || this.form.password === "") {
    //   this.validation = false;
    // } else {
    //   this.validation = true;
    //   this.userSer.create(this.form).subscribe((result: any) => {
    //     if (result.status === "success") {
    //       this.modalService.dismissAll("");
    //       this.exist = false;
    //       this.ngOnInit();
    //     } else {
    //       this.exist = true;
    //     }
    //   });
    // }
  }

  _update(id) {
    this.updateForm = {};
    this.emailCheck = false;
    this.orgNameCheck = false;
    this.image1 = null;
    this.image2 = null;
    this.image3 = null;
    this.loadingIndicator = true;
    this.userSer.getUserInfoById(id).subscribe((result: any) => {
      if (result.status === "success") {
        this.updateForm = result.data;
        console.log(this.updateForm);
        if (this.updateForm.role == "Supporter") {
          this.supporterCheck = true;
        }
        if (this.updateForm.role == "Divine Nine Member") {
          this.supporterCheck = false;
          if (this.updateForm.organization.color[0] == "#") {
            this.updateForm.organization.color = this.updateForm.organization.color;
          } else {
            this.updateForm.organization.color = "#" + this.updateForm.organization.color;
          }
        }
        this.updateForm.dob = this.datepipe.transform(this.updateForm.dob, "yyyy-MM-dd");
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  isUpdating = false;
  doUpdate() {
    this.isUpdating = true;
    let validation = false;
    if (this.supporterCheck) {
      validation = true;
    }
    if (!this.supporterCheck) {
      let checkNum = 0;
      if (this.updateForm.email == null || this.updateForm.email == "") {
        this.emailCheck = true;
      } else {
        this.emailCheck = false;
        checkNum = checkNum + 1;
      }
      if (this.updateForm.organization.organization == null || this.updateForm.organization.organization == "") {
        this.orgNameCheck = true;
      } else {
        let orgIndex = this.OrganizationList.findIndex((d) => d.name === this.updateForm.organization.organization);
        this.updateForm.organization.color = this.OrganizationList[orgIndex].color;
        this.updateForm.organization.logo = this.OrganizationList[orgIndex].logo;
        this.orgNameCheck = false;
        checkNum = checkNum + 1;
      }
      if (checkNum == 2) {
        validation = true;
      }
    }
    if (validation) {
      this.loadingIndicator = true;
      if (this._fileSelected1) {
        let folderPath = "UserImages/" + this.updateForm.organization.organization.replace(/\s+/g, "_");
        this.uploaderSer.uploader(this.image1, folderPath).subscribe((fileName: any) => {
          this.updateForm.avatar = fileName.data;
          this._fileSelected1 = false;
        });
      }
      if (this._fileSelected2) {
        let folderPath = "UserImages/" + this.updateForm.organization.organization.replace(/\s+/g, "_");
        this.uploaderSer.uploader(this.image2, folderPath).subscribe((fileName: any) => {
          this.updateForm.memberproof.idImage = fileName.data;
          this._fileSelected2 = false;
        });
      }
      if (this._fileSelected3) {
        let folderPath = "UserImages/" + this.updateForm.organization.organization.replace(/\s+/g, "_");
        this.uploaderSer.uploader(this.image3, folderPath).subscribe((fileName: any) => {
          this.updateForm.memberproof.membershipImage = fileName.data;
          this._fileSelected3 = false;
        });
      }
      if (this._fileSelected4) {
        let folderPath = "UserImages/" + this.updateForm.organization.organization.replace(/\s+/g, "_");
        this.uploaderSer.uploader(this.image4, folderPath).subscribe((fileName: any) => {
          this.updateForm.memberproof.membershipImage = fileName.data;
          this._fileSelected4 = false;
        });
      }

      this.checkerRunning = false;
      this.intervalId = setInterval(() => {
        if (!this.checkerRunning && !this._fileSelected1 && !this._fileSelected2 && !this._fileSelected3 && !this._fileSelected4) {
          this.checkerRunning = true;
          this.checkUploader();
        }
      }, 100);
    }
  }

  checkUploader() {
    clearInterval(this.intervalId);
    this.userSer.update(this.updateForm).subscribe((result: any) => {
      this.isUpdating = false;
      if (result.status === "success") {
        clearInterval(this.intervalId);
        this.modalService.dismissAll("");
        this.loadData();
        this.showToast("Success", "User Updated Successfully");
      } else {
        this.exist = true;
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  selectId(id) {
    this.selectedId = id;
    this.selectedIndex = this.allUser.findIndex((d) => d.id === this.selectedId);
  }

  doDelete() {
    this.loadingIndicator = true;
    this.userSer.delete({ id: this.selectedId }).subscribe((result: any) => {
      if (result.status === "success") {
        this.allUser[this.selectedIndex].status = "block";
        this.modalService.dismissAll("");
        this.showToast("Success", "Successfully Updated");
        this.allUser.splice(this.selectedIndex, 1);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  forStatus;
  status(id) {
    let index = this.allUser.findIndex((d) => d.id === id);
    if (this.allUser[index].status == "active") {
      return "Block";
    } else {
      return "Activate";
    }
  }
  statusAction(id) {
    this.forStatus = id;
  }

  statusDoAction() {
    let index = this.allUser.findIndex((d) => d.id === this.forStatus);

    let v;
    if (this.allUser[index].status == "active") {
      v = "blocked";
    } else {
      v = "active";
    }
    this.loadingIndicator = true;
    var temp = { status: v, id: this.forStatus };
    this.userSer.statusUpdate(temp).subscribe((result: any) => {
      if (result.status === "success") {
        this.modalService.dismissAll("");
        this.showToast("Success", "Successfully Updated");
        this.allUser[index].status = v;
        this.setter(this.allUser);
      } else {
        this.showToast("Error", "Something went wrong");
      }
      this.loadingIndicator = true;
    });
  }

  forVerification;
  selectIdVerify(id) {
    let selectedId = id;
    let selectedIndex = this.allUser.findIndex((d) => d.id === selectedId);
    if (this.allUser[selectedIndex].isVerified) {
      return "Unverify";
    }
    if (!this.allUser[selectedIndex].isVerified) {
      return "Verify";
    }
  }

  verifyAction(id) {
    this.forVerification = id;
  }

  verifyDoAction() {
    let index = this.allUser.findIndex((d) => d.id === this.forVerification);

    let v;
    if (this.allUser[index].isVerified == this.forVerification) {
      v = false;
    } else {
      v = true;
    }
    this.loadingIndicator = true;
    var temp = { isVerified: v, id: this.forVerification };
    this.userSer.verificationUpdate(temp).subscribe((result: any) => {
      if (result.status === "success") {
        this.modalService.dismissAll("");
        this.showToast("Success", "Successfully Updated");
        this.allUser[index].isVerified = v;
        this.setter(this.allUser);
      } else {
        this.showToast("Error", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  forPayment;
  forPaymentStatus;
  selectIdPayment(id) {
    let selectedId = id;
    let selectedIndex = this.allUser.findIndex((d) => d.id === selectedId);
    if (this.allUser[selectedIndex].isPaid) {
      return "Downgrade Premium";
    }
    if (!this.allUser[selectedIndex].isPaid) {
      return "Premium Upgrade";
    }
  }
  paymentAction(id) {
    this.forPayment = id;
    let selectedIndex = this.allUser.findIndex((d) => d.id === id);
    this.forPaymentStatus = this.allUser[selectedIndex].isPaid;
  }

  paymentDoAction() {
    let index = this.allUser.findIndex((d) => d.id === this.forPayment);
    this.loadingIndicator = true;

    var temp = { isPaid: !this.allUser[index].isPaid, isOldUser: !this.allUser[index].isPaid, id: this.forPayment };
    this.userSer.paymentUpdate(temp).subscribe((result: any) => {
      if (result.status === "success") {
        this.modalService.dismissAll("");
        this.showToast("Success", "Successfully Updated");
        this.allUser[index].isOldUser = !this.allUser[index].isPaid;
        this.allUser[index].isPaid = !this.allUser[index].isPaid;
        this.setter(this.allUser);
      } else {
        this.showToast("Error", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  forAge;
  forAgeStatus;
  selectIdAge(id) {
    let selectedId = id;
    let selectedIndex = this.allUser.findIndex((d) => d.id === selectedId);
    if (this.allUser[selectedIndex].isOldUser) {
      return "Set as New";
    }
    if (!this.allUser[selectedIndex].isOldUser) {
      return "Set as Old";
    }
  }
  showValue(id) {
    let selectedId = id;
    let selectedIndex = this.allUser.findIndex((d) => d.id === selectedId);
  }
  ageAction(id) {
    this.forAge = id;
    let selectedIndex = this.allUser.findIndex((d) => d.id === id);
    this.forAgeStatus = this.allUser[selectedIndex].isOldUser;
  }

  ageDoAction() {
    let index = this.allUser.findIndex((d) => d.id === this.forAge);
    this.loadingIndicator = true;

    var temp = { isOldUser: !this.allUser[index].isOldUser, id: this.forAge };
    this.userSer.ageUpdate(temp).subscribe((result: any) => {
      if (result.status === "success") {
        this.modalService.dismissAll("");
        this.showToast("Success", "Successfully Updated");
        this.allUser[index].isOldUser = !this.allUser[index].isOldUser;
        this.setter(this.allUser);
      } else {
        this.showToast("Error", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }
  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }

  //
  // Bootstrap Modals
  //

  open(content, options = {}) {
    this.modalService.open(content, options).result.then(
      (result) => {},
      (reason) => {}
    );
  }


  filterChanged(value) {
this.filterType =value;
  }

  // Table
  updateFilter(event) {

    if(this.filterType == "email") {
      const val = event.target.value.toLowerCase();
      const temp = this.allUser.filter((d) => {
        return d.email.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.setter(temp);
      this.table.offset = 0;
    } else if(this.filterType == "prophytesId") {
      const val = event.target.value.toLowerCase();
      const temp = this.allUser.filter((d) => {
        return d.prophytesId.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.setter(temp);
      this.table.offset = 0;
    }  else if(this.filterType == "phoneNumber") {
      const val = event.target.value.toLowerCase();
      const temp = this.allUser.filter((d) => {
        return d.phone_number.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.setter(temp);
      this.table.offset = 0;
    } 
  }
}
