import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent } from "@swimlane/ngx-datatable";

import { VerificationRequestService } from "../_services/verification-request.service";
import { AppService } from "../app.service";
import { UsersService } from "../_services/users.service";

@Component({
  selector: "app-verification-request",
  templateUrl: "./verification-request.component.html",
  styleUrls: [
    "./verification-request.component.scss",
    "../../../node_modules/@swimlane/ngx-datatable/index.scss",
    "../../vendor/libs/ngx-datatable/ngx-datatable.scss",
    "../../../node_modules/@swimlane/ngx-datatable/assets/icons.css",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class VerificationRequestComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  // variables
  newStatus: any;
  forUpdateId: any;
  allRequests = [];

  // table
  loadingIndicator = true;
  selected = [];
  rows = [];
  selectedId: any;
  selectedIndex: any;
  selectedIdRows: any;

  constructor(
    private appService: AppService,
    private verifySer: VerificationRequestService,
    private modalService: NgbModal,
    private userSer: UsersService,
    public datepipe: DatePipe,
    public toastrService: ToastrService
  ) {
    this.appService.pageTitle = "Verification Requests";
  }

  ngOnInit() {
    this.loadingIndicator = true;
    this.verifySer.getAll().subscribe((result: any) => {
      this.allRequests = result.data;
      this.loadingIndicator = false;
      this.setter(this.allRequests);
    });
  }

  selectId(id: any) {
    this.selectedId = id;
    this.selectedIndex = this.allRequests.findIndex((d) => d.id === this.selectedId);
  }

  closeDetailsModal() {
    this.selectedIdRows = undefined;
    this.selectId = undefined;
  }

  getUserInfoById() {
    this.loadingIndicator = true;
    this.userSer.getUserInfoById(this.selectedId).subscribe((result: any) => {
      if (result.status === "success") {
        this.selectedIdRows = result.data;
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  setter(arr) {
    let tempRows = [];

    for (const item of arr) {
      if (!item.user) continue;

      if (item.status == "verified") continue;

      tempRows.push({
        firstName: item.user.firstName || "",
        lastName: item.user.lastName || "",
        email: item.user.email,
        nationalId: item.memberproof.idImage,
        membershipImage: item.memberproof.membershipImage,
        stateIdImage: item.memberproof.stateIdImage,
        organization: item.memberproof.organization,
        initiatedChapter: item.memberproof.initiatedChapter1 + " " + item.memberproof.initiatedChapter2 + " " + item.memberproof.initiatedChapter3,
        seasonInitiated: item.memberproof.seasonInitiated,
        yearInitiated: item.memberproof.yearInitiated,
        requestedDate: this.datepipe.transform(item.createdAt, "dd-MM-yyyy"),
        status: item.status,
        id: item.user.id,
      });
    }

    this.rows = tempRows;
  }

  changeStatus(id, status) {
    this.newStatus = status;
    this.forUpdateId = id;
  }

  // isVerified(r_id) {
  //   let index = this.allRequests.findIndex(d => d.id === r_id);
  //   console.log(this.allRequests[index]);
  //   return this.allRequests[index].status == "pending";
  // }

  // verify(id) {
  //   this.forUpdateId = id;
  //   let index = this.allRequests.findIndex(d => d.id === this.forUpdateId);
  //   this.allRequests[index].status = 'verified';
  //   this.doUpdate(index);
  // }

  doUpdate() {
    let i = this.allRequests.findIndex((d) => {
      if (d.user) {
        return d.user.id === this.forUpdateId;
      } else {
        return false;
      }
    });
    console.log(this.allRequests[i]);
    let form = {
      verificationId: this.allRequests[i].id,
      userId: this.allRequests[i].userId,
      memberproofId: this.allRequests[i].memberproofId,
      memberproof: {},
      user: {},
      verification: {},
    };
    if (this.newStatus == "Approved") {
      form.user = { isVerified: true };
      form.memberproof = { status: "Approved" };
      form.verification = { status: "verified" };
      this.allRequests[i].status = "verified";
    } else if (this.newStatus == "Resubmit") {
      form.user = { isVerified: false };
      form.memberproof = { status: "Resubmit" };
      form.verification = { status: "waiting for resubmit" };
      this.allRequests[i].status = "waiting for resubmit";
    } else if (this.newStatus == "Denied") {
      form.user = { isVerified: false };
      form.memberproof = { status: "Denied" };
      form.verification = { status: "denied" };
      this.allRequests[i].status = "denied";
    }

    this.loadingIndicator = true;
    this.verifySer.update(form).subscribe((result: any) => {
      if (result.status === "success") {
        this.showToast("Success", "Status Updates");
        this.setter(this.allRequests);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  // Table
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allRequests.filter((d) => {
      return d.user.email.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.setter(temp);
    this.table.offset = 0;
  }

  //
  // Bootstrap Modals
  //
  open(content, options = {}) {
    this.modalService.open(content, options);
  }

  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}
