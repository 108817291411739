import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {


  private url = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getDashboard() {
    return this.http.get(this.url + "dashboard/counterWidget");
  }
  updateProfile(data) {
    return this.http.post(this.url + "auth/userUpdate",data);
  }
  changePassword(data) {
    return this.http.post(this.url + "auth/changePassword",data);
  }

}
