import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent, NgxDatatableModule } from "@swimlane/ngx-datatable";
import { UsersService } from "src/app/_services/users.service";
import { environment } from "src/environments/environment";
import { AppService } from "../app.service";
import { AlumniCityService } from "../_services/alumni-city.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-city",
  templateUrl: "./alumni-city.component.html",
  styleUrls: [
    "./alumni-city.component.scss",
    "../../../node_modules/@swimlane/ngx-datatable/index.scss",
    "../../vendor/libs/ngx-datatable/ngx-datatable.scss",
    "../../../node_modules/@swimlane/ngx-datatable/assets/icons.css",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AlumniCityComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  // variables
  form = { name: "" };
  validation = true;
  forUpdata: any;
  forDelete: any;
  allCity = [];

  // table
  loadingIndicator = true;
  selected = [];
  rows = [];

  constructor(private appService: AppService, private citySer: AlumniCityService, private modalService: NgbModal, public datepipe: DatePipe, public toastrService: ToastrService) {
    this.appService.pageTitle = "City/Town";
  }

  ngOnInit() {
    this.loadingIndicator = true;
    this.citySer.getAll().subscribe((result: any) => {
      if (result["status"] == "success") {
        this.loadData(result.data);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  // filters results
  loadData(data) {
    this.allCity = this.allCity.concat(data);
    this.loadingIndicator = false;
    let tempRows = [];
    this.allCity.forEach((item: any) => {
      tempRows.push({
        name: item.name,
        updatedAt: this.datepipe.transform(item.updatedAt, "dd-MM-yyyy"),
        id: item.id,
      });
      this.rows = tempRows;
    });
  }

  create() {
    if (this.form.name === "") {
      this.validation = false;
    } else {
      this.validation = true;
      this.loadingIndicator = true;
      this.citySer.create(this.form).subscribe((result: any) => {
        if (result.status === "success") {
          this.showToast("Success", "Successfully Created");
          this.modalService.dismissAll("");
          var temp = [];
          temp.push(result.data);
          this.loadData(temp);
        } else {
          this.showToast("Success", "Something went wrong");
        }
        this.loadingIndicator = false;
      });
    }
  }

  _update(id) {
    this.forUpdata = id;
    let index = this.allCity.findIndex((d) => d.id === this.forUpdata);
    this.form.name = this.allCity[index].name;
  }
  doUpdate() {
    if (this.form.name === "") {
      this.validation = false;
    } else {
      this.validation = true;
      this.loadingIndicator = true;
      this.citySer.update(this.form, this.forUpdata).subscribe((result: any) => {
        if (result.status === "success") {
          this.showToast("Success", "Successfully Updated");
          this.modalService.dismissAll("");
          let index = this.allCity.findIndex((d) => d.id === this.forUpdata);
          this.allCity[index].name = this.form.name;
          let tempRows = [];
          this.allCity.forEach((item: any) => {
            tempRows.push({
              name: item.name,
              createdAt: this.datepipe.transform(item.createdAt, "dd-MM-yyyy"),
              id: item.id,
            });
            this.rows = tempRows;
          });
        } else {
          this.showToast("Success", "Something went wrong");
        }
        this.loadingIndicator = false;
      });
    }
  }

  _delete(id) {
    this.forDelete = id;
  }

  doDelete() {
    this.loadingIndicator = true;
    this.loadingIndicator = true;
    this.citySer.delete(this.forDelete).subscribe((result: any) => {
      if (result.status === "success") {
        this.showToast("Success", "Successfully Deleted");
        let index = this.allCity.findIndex((d) => d.id === this.forDelete);
        this.allCity.splice(index, 1);
        this.loadingIndicator = false;
        if (this.allCity.length == 0) {
          this.rows = [];
        }
        let tempRows = [];
        this.allCity.forEach((item: any) => {
          tempRows.push({
            name: item.name,
            createdAt: this.datepipe.transform(item.createdAt, "dd-MM-yyyy"),
            id: item.id,
          });
          this.rows = tempRows;
        });
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  //
  // Bootstrap Modals
  //
  open(content, options = {}) {
    this.modalService.open(content, options);
  }
  // Table
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allCity.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}
