import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private url = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }

  getAll() {
    return this.http.post(this.url + "connection/getAll", { 'data': null });
  }
  statusUpdate(data) {
    return this.http.post(this.url + "connection/statusUpdate/", data);
  }
}
