import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private url = environment.apiUrl;
  private options;

  constructor(private http: HttpClient, private router: Router) {
    this.options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  login(data) {
    return this.http.post(this.url + "auth/login", data, this.options);
  }
}
