import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  DatatableComponent,
  NgxDatatableModule,
} from "@swimlane/ngx-datatable";
import { environment } from "src/environments/environment";
import { AppService } from "../app.service";
import { MembershipDuesService } from "../_services/membership-dues.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-membership-dues',
  templateUrl: './membership-dues.component.html',
  styleUrls: ['./membership-dues.component.scss',
    "../../../node_modules/@swimlane/ngx-datatable/index.scss",
    "../../vendor/libs/ngx-datatable/ngx-datatable.scss",
    "../../../node_modules/@swimlane/ngx-datatable/assets/icons.css",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MembershipDuesComponent implements OnInit {



  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  // variables
  private url = environment.baseUrl;
  form = {
    name: "",
  };
  updateForm = {
    id: "",
    status: 'Approved',
    cardOrganizationText: "",
    cardOwnerName: "",
    cardMemberNo: "",
    cardChapter: "",
    cardExpire: "",
  };
  cardOrganizationTextCheck = false;
  cardOwnerNameCheck = false;
  cardMemberNoCheck = false;
  cardChapterCheck = false;
  cardExpireCheck = false;
  validation = true;
  forUpdata: any;
  forDelete: any;
  allDues = [];
  allDuesTwo = [];

  // table
  loadingIndicator = true;
  selected = [];


  constructor(
    private appService: AppService,
    private duesSer: MembershipDuesService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    public toastrService: ToastrService,
  ) {
    this.appService.pageTitle = "Membership Dues";
  }


  ngOnInit() {
    this.loadingIndicator = true;
    this.duesSer.getAll().subscribe((result: any) => {
      this.allDues = result.data;
      this.allDuesTwo = result.data;
      this.loadingIndicator = false;
    });
  }


  statusCheck(r_id, option) {
    let index = this.allDues.findIndex(d => d.id === r_id);
    return (this.allDues[index].status == option);
  }

  setForm(id) {
    this.forUpdata = id;
    let index = this.allDues.findIndex(d => d.id === this.forUpdata);
    this.updateForm = {
      id: this.allDues[index].id,
      status: this.allDues[index].status,
      cardOrganizationText: this.allDues[index].cardOrganizationText,
      cardOwnerName: this.allDues[index].cardOwnerName,
      cardMemberNo: this.allDues[index].cardMemberNo,
      cardChapter: this.allDues[index].cardChapter,
      cardExpire: this.datepipe.transform(this.allDues[index].cardExpire, 'yyyy-MM-dd'),
    };
  }

  doApprove() {    
    let numCheck = 0;
    if (this.updateForm.cardOrganizationText == '' || this.updateForm.cardOrganizationText == null) { 
      this.cardOrganizationTextCheck = true;
    }else{
      this.cardOrganizationTextCheck = false;
      numCheck++;
    }
    if (this.updateForm.cardOwnerName == '' || this.updateForm.cardOwnerName == null) { 
      this.cardOwnerNameCheck = true;
    }else{
      this.cardOwnerNameCheck = false;
      numCheck++;
    }
    if (this.updateForm.cardMemberNo == '' || this.updateForm.cardMemberNo == null) { 
      this.cardMemberNoCheck = true;
    }else{
      this.cardMemberNoCheck = false;
      numCheck++;
    }
    if (this.updateForm.cardChapter == '' || this.updateForm.cardChapter == null) { 
      this.cardChapterCheck = true;
    }else{
      this.cardChapterCheck = false;
      numCheck++;
    }
    if (this.updateForm.cardExpire == '' || this.updateForm.cardExpire == null) { 
      this.cardExpireCheck = true;
    }else{
      this.cardExpireCheck = false;
      numCheck++;
    }

    if (numCheck == 5) {      
      let form = this.updateForm;
      form.cardExpire = this.datepipe.transform(form.cardExpire, 'MM/dd/yyyy');
      form.status = 'Approved';
      this.forUpdata = this.updateForm.id;
      let index = this.allDues.findIndex(d => d.id === this.forUpdata);
      this.allDues[index].status = form.status;
      this.allDues[index].cardOrganizationText = form.cardOrganizationText;
      this.allDues[index].cardOwnerName = form.cardOwnerName;
      this.allDues[index].cardMemberNo = form.cardMemberNo;
      this.allDues[index].cardChapter = form.cardChapter;
      this.allDues[index].cardExpire = form.cardExpire;
      this.allDuesTwo[index].status = form.status;
      this.allDuesTwo[index].cardOrganizationText = form.cardOrganizationText;
      this.allDuesTwo[index].cardOwnerName = form.cardOwnerName;
      this.allDuesTwo[index].cardMemberNo = form.cardMemberNo;
      this.allDuesTwo[index].cardChapter = form.cardChapter;
      this.allDuesTwo[index].cardExpire = form.cardExpire;
      this.modalService.dismissAll("");

      this.duesSer.update(form).subscribe((result: any) => {
        if (result.status === "success") {
          this.showToast("Success", "Updated Sucessfully");
          // this.setter(this.allDues);
        } else {
          this.showToast("Success", "Something went wrong");
        }
        this.loadingIndicator = false;
      });
    }

  }


  changeStatus(id, status) {
    this.forUpdata = id;
    let index = this.allDues.findIndex(d => d.id === this.forUpdata);
    this.allDues[index].status = status;
    this.allDuesTwo[index].status = status;
    this.doUpdate(index, status);
  }



  doUpdate(i, status) {
    let form = {
      id: this.allDues[i].id,
      status: status,
    }
    this.loadingIndicator = true;
    this.duesSer.update(form).subscribe((result: any) => {
      if (result.status === "success") {
        this.showToast("Success", "Updated Sucessfully");
        // this.setter(this.allDues);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  // Table
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allDuesTwo.filter((d) => {
      return d.cardMemberNo.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.allDues = temp;
    
    // this.setter(temp);
    this.table.offset = 0;
  }


  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }

  open(content, options = {}) {
    this.modalService.open(content, options);
  }

}

