import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { ConnectionService } from '../_services/connection.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss',
    '../../../node_modules/@swimlane/ngx-datatable/index.scss',
    '../../vendor/libs/ngx-datatable/ngx-datatable.scss',
    '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ConnectionsComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  loadingIndicator = true;
  dataRows = [];
  searchRows = [];
  forStatus: any;
  constructor(
    private appService: AppService,
    private connSer: ConnectionService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    public toastrService: ToastrService,

  ) {
    this.appService.pageTitle = "Connection";
  }
  ngOnInit() {
    this.onLoad();
  }

  onLoad() {
    this.loadingIndicator = true;
    this.connSer.getAll().subscribe((result: any) => {
      if (result['status'] == 'success') {
        this.searchRows = result.data;
        this.dataRows = result.data;
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  status(id) {
    let index = this.dataRows.findIndex(d => d.id === id);
    if (this.dataRows[index].status == 'connect') {
      return 'Disconnect';
    } else {
      return 'Connect';
    }
  }
  statusAction(id) {
    this.forStatus = id;
  }

  statusDoAction() {
    let index = this.dataRows.findIndex(d => d.id === this.forStatus);

    let v;
    if (this.dataRows[index].status == 'connect') {
      v = 'pending';
    } else {
      v = 'connect';
    }
    this.loadingIndicator = true;

    var temp = { status: v, id: this.forStatus };
    this.connSer.statusUpdate(temp).subscribe((result: any) => {
      if (result.status === "success") {
        this.showToast("Success", "Successfully Updated");
        console.log(this.dataRows[index].status);
        this.dataRows[index].status = v;
        console.log(this.dataRows[index].status);
        this.modalService.dismissAll("");
      } else {
        this.showToast("Error", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.searchRows.filter((d) => {
      return d.sender.email.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.dataRows = temp;
    this.table.offset = 0;
  }
  open(content, options = {}) {
    this.modalService.open(content, options).result.then(
      (result) => { },
      (reason) => { }
    );
  }

  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}
