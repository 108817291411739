import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TheYardService {
  private url = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }

  getAll() {
    return this.http.post(this.url + "yard/getAll", { 'data': null });
  }
  getComments(postId) {
    return this.http.post(this.url + "yard/getComments", { 'postId': postId });
  }
  create(data) {
    return this.http.post(this.url + "yard/create", data);
  }
  update(data) {
    return this.http.post(this.url + "yard/update/", data);
  }
  deleteComment(data) {
    return this.http.post(this.url + "yard/deleteComment/", data);
  }
  delete(id) {
    return this.http.post(this.url + "yard/delete/", { 'id': id });
  }
  metadata(url) {
    return this.http.post(this.url + "services/metadata/", { 'url': url });
  }
}
