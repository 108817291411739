import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AlumniCityService {
  private url = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) {}

  getAll() {
    return this.http.get(this.url + "alumni_city/getAll");
  }
  create(data) {
    return this.http.post(this.url + "alumni_city/create", data);
  }
  update(data, id) {
    return this.http.post(this.url + "alumni_city/update/" + id, data);
  }
  delete(id) {
    return this.http.get(this.url + "alumni_city/delete/" + id);
  }
}
