import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  DatatableComponent,
  NgxDatatableModule,
} from "@swimlane/ngx-datatable";
import { UsersService } from "src/app/_services/users.service";
import { UploaderService } from "../_services/uploader.service";
import { environment } from "src/environments/environment";
import { AppService } from "../app.service";
import { ToastrService } from 'ngx-toastr';
import { TheYardService } from "../_services/the-yard.service";

@Component({
  selector: 'app-the-yard',
  templateUrl: './the-yard.component.html',
  styleUrls: ['./the-yard.component.scss',
    '../../../node_modules/@swimlane/ngx-datatable/index.scss',
    '../../vendor/libs/ngx-datatable/ngx-datatable.scss',
    '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css'
  ],
  encapsulation: ViewEncapsulation.None,

})
export class TheYardComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(
    private appService: AppService,
    private yardSer: TheYardService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private uploaderSer: UploaderService,
    public toastrService: ToastrService,

  ) {
    this.appService.pageTitle = "The Yard";
  }

  // variables
  form: any;
  updateForm: any;
  validation = true;
  forUpdata: any;
  viewRow: [];
  linkCheck = false;
  orgCheck = false;
  viewRowIndex: any;
  viewRowId: any;
  forDelete: any;
  forDeleteIndex: any;
  forCommentDeleteId: any;
  forCommentDeleteIndex: any;
  allPosts = [];
  currentComments = [];
  singleSelectValue: any;
  // table
  loadingIndicator = true;
  selected = [];
  rows = [];

  _fileSelected = false;
  imageCheck = false;
  image: File = null;
  imageSave(event) {
    this.image = <File>event.target.files[0];
    if (this.image != null) {
      if (this.image['type'].split('/')[0] == 'image') {
        this._fileSelected = true;
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.updateForm.file = event.target.result;
        }

        reader.readAsDataURL(event.target.files[0]);
      } else {
        this.image = this.updateForm.file;
        this.imageCheck = true;
        this._fileSelected = false;
      }
    } else {
      this._fileSelected = false;
    }
  }

  tagsAndFriends() {
    if (this.updateForm.caption != null && this.updateForm.caption != '') {
      let mainSplittedString = this.updateForm.caption.replace(/\s+/g, ' ').trim().split(" ");
      let tags = [];
      let friends = [];
      for (let i = 0; i < mainSplittedString.length; i++) {
        if (mainSplittedString[i][0] == '#') {
          tags.push(mainSplittedString[i].substr(1));
        }
        if (mainSplittedString[i][0] == '@') {
          friends.push(mainSplittedString[i].substr(1));
        }
      }
      this.updateForm.tags = tags.join(',');
      this.updateForm.friends = friends.join(',');
    }
  }

  categories = [
    'Strolls',
    'Probates',
    'Steps',
    'Community',
    'Educational',
    'News',
    'Shenanigans',
    'Unity',
  ];

  whoCanWatch = [
    'Everyone',
    'Only Friends',
  ];

  OrganizationList = [
    {
      "name": 'Alpha Phi Alpha',
      "logo": 'Alpha Phi Alpha.png',
      "color": '000000',
    },
    {
      "name": 'Alpha Kappa Alpha',
      "logo": 'Alpha Kappa Alpha.png',
      "color": 'DDABC7',
    },
    {
      "name": 'Kappa Alpha Psi',
      "logo": 'Kappa Alpha Psi.png',
      "color": '9A0000',
    },
    {
      "name": 'Omega Psi Phi',
      "logo": 'Omega Psi Phi.png',
      "color": '3C0651',
    },
    {
      "name": 'Delta Sigma Theta',
      "logo": 'Delta Sigma Theta.png',
      "color": 'DF0000',
    },
    {
      "name": 'Phi Beta Sigma',
      "logo": 'Phi Beta Sigma.png',
      "color": '1C3DC8',
    },
    {
      "name": 'Zeta Phi Beta',
      "logo": 'Zeta Phi Beta.png',
      "color": '1C3DC8',
    },
    {
      "name": 'Sigma Gamma Rho',
      "logo": 'Sigma Gamma Rho.png',
      "color": 'FFBE00',
    },
    {
      "name": 'Iota Phi Theta',
      "logo": 'Iota Phi Theta.png',
      "color": '663201',
    }
  ];

  commentsPermissions = [
    {
      "label": 'Allowed',
      "value": true,
    },
    {
      "label": 'Not Allowed',
      "value": false,
    },
  ];
  postTypes = [
    {
      "label": 'Post',
      "value": 'post',
    },
    {
      "label": 'Article',
      "value": 'article',
    },
  ];

  ngOnInit() {
    this.loadingIndicator = true;
    this.yardSer.getAll().subscribe((result: any) => {
      if (result['status'] == 'success') {
        this.allPosts = result.data;
        this.loadData(result.data);
        this.loadingIndicator = false;
      } else {
        this.showToast("Success", "Something went wrong");
      }
    });
  }

  // filters results
  loadData(data) {
    let tempRows = [];
    data.forEach((item: any) => {
      tempRows.push({
        id: item.id,
        postHeading: item.heading == '' ? '-' : item.heading,
        postCaption: item.caption == '' ? '-' : item.caption,
        postFile: item.file,
        postType: item.type,
        postLink: item.link == '' ? '-' : item.link,
        postLikes: item.likes == '' ? '-' : item.likes,
        postComments: item.comments == '' ? '-' : item.comments,
        userEmail: item.user.email == '' ? '-' : item.user.email,
        userProfileImage: item.user.avatar == '' ? 'assets/avatar.png' : item.user.avatar,
      });
      this.rows = tempRows;
    });
  }

  create() {
    if (this.form.name === "") {
      this.validation = false;
    } else {
      this.validation = true;
      this.loadingIndicator = true;
      this.yardSer.create(this.form).subscribe((result: any) => {
        if (result.status === "success") {
          this.showToast("Success", "Successfully Created");

          this.modalService.dismissAll("");
          var temp = [];
          temp.push(result.data);
          this.loadData(temp);
        } else {
          this.showToast("Success", "Something went wrong");
        }
        this.loadingIndicator = false;
      });
    }
  }

  emptyReturn(data, replacement) {
    if (data == '' || data == null) {
      return replacement;
    } else {
      return data;
    }
  }
  getComments(postId) {
    this.loadingIndicator = true;
    this.yardSer.getComments(postId).subscribe((result: any) => {
      if (result['status'] == 'success') {
        this.currentComments = result.data;
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  _view(id) {
    this.viewRowId = id;
    this.viewRowIndex = this.allPosts.findIndex(d => d.id === id);
    this.viewRow = this.allPosts[this.viewRowIndex];
  }
  selectCommentId(id) {
    this.forCommentDeleteId = id;
    this.forCommentDeleteIndex = this.currentComments.findIndex(d => d.id === this.forCommentDeleteId);
  }
  doDeleteComment() {
    let data = { id: this.forCommentDeleteId, postId: this.viewRowId, };
    this.loadingIndicator = true;
    this.yardSer.deleteComment(data).subscribe((result: any) => {
      if (result.status === "success") {
        this.currentComments.splice(this.forCommentDeleteIndex, 1);
        this.viewRow['comments'] = this.viewRow['comments'] - 1;
        this.rows[this.viewRowIndex].postComments = this.rows[this.viewRowIndex].postComments - 1;
        if (this.currentComments.length == 0) {
          this.modalService.dismissAll("");
        }
        this.showToast("Success", "Successfully Deleted");
        this.ngOnInit();
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }
  _update(id) {
    this.linkCheck = false;
    this.orgCheck = false;
    this.forUpdata = id;
    let index = this.allPosts.findIndex(d => d.id === this.forUpdata);
    this.updateForm = this.allPosts[index];
    this.tagsAndFriends();
  }
  url: any;
  getMetadata() {
    if ((this.updateForm.link != '' && this.updateForm.link != null && this.updateForm.link != this.url)) {
      this.url = this.updateForm.link
      this.yardSer.metadata(this.updateForm.link).subscribe((result: any) => {
        if (result.status === "success") {
          console.log(result.data);
          let data = result.data;
          this.updateForm.heading = data.title;
          this.updateForm.caption = data.description;
          this.updateForm.file = data.image;
        } else {
          console.log(result.data);
        }
      });
    }
  }


  doUpdate() {

    let fieldCheck = 0
    if ((this.updateForm.file == '' || this.updateForm.file == null) && this.updateForm.type == 'article') {
      this.updateForm.file == '-';
    }
    if (this.updateForm.link == '' && this.updateForm.type == 'article') {
      this.linkCheck = true;
    } else {
      this.linkCheck = false;
      fieldCheck += 1;
    }
    if (this.updateForm.organization == '' && this.updateForm.organization == null) {
      this.orgCheck = true;
    } else {
      this.orgCheck = false;
      fieldCheck += 1;
    }
    if (this._fileSelected && fieldCheck == 2) {
      this.loadingIndicator = true;
      let folderPath = 'The-Yard/' + this.updateForm.organization.replace(/\s+/g, '_') + '/' + this.updateForm.category;
      this.uploaderSer.uploader(this.image, folderPath).subscribe((fileName: any) => {
        this.updateForm.file = fileName.data;
        this._fileSelected = false;
        this.updating();
        this.loadingIndicator = false;
      });
    } else {
      this.updating();
    }
  }
  updating() {
    this.loadingIndicator = true;
    this.yardSer.update(this.updateForm,).subscribe((result: any) => {
      if (result.status === "success") {
        this.showToast("Success", "Successfully Updated");
        this.modalService.dismissAll("");
        let index = this.allPosts.findIndex(d => d.id === this.forUpdata.id);
        this.allPosts[index] = this.updateForm;
        this.loadData(this.allPosts);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  _delete(id) {
    this.forDelete = id;
    this.forDeleteIndex = this.rows.findIndex(d => d.id === id);
  }

  doDelete() {
    this.loadingIndicator = true;
    this.yardSer.delete(this.forDelete).subscribe((result: any) => {
      if (result.status === "success") {
        this.showToast("Success", "Successfully Deleted");
        this.allPosts.splice(this.forDeleteIndex, 1);
        this.loadData(this.allPosts);
      } else {
        this.showToast("Success", "Something went wrong");
      }
      this.loadingIndicator = false;
    });
  }

  //
  // Bootstrap Modals
  //
  open(content, options = {}) {
    this.modalService.open(content, options);
  }

  // Table
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.allPosts.filter((d) => {
      return d.user.email.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.loadData(temp);
    this.table.offset = 0;
  }


  //
  // ngx-toastr
  //
  showToast(title, message) {
    let type = "info";
    const options = {
      tapToDismiss: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-top-right",
      rtl: this.appService.isRTL,
    };
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;
    this.toastrService[type](message, title, options);
  }
}
