import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// *******************************************************************************
// NgBootstrap

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgSelectModule } from "@ng-select/ng-select";
import { ToastrModule } from "ngx-toastr";

// *******************************************************************************
// App

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { LayoutModule } from "./layout/layout.module";

// *******************************************************************************
// Services
import { AppService } from "./app.service";
import { AuthService } from "./_services/auth.service";
// *******************************************************************************
// Pages

import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProfileComponent } from "./profile/profile.component";
import { UserAllComponent } from "./users/user-all/user-all.component";
import { UserViewComponent } from "./users/user-view/user-view.component";
import { UsersService } from "./_services/users.service";
import { TokenInterceptorService } from "./_services/token-interceptor.service";
import { DatePipe } from "@angular/common";
import { VerificationRequestComponent } from "./verification-request/verification-request.component";
import { CityComponent } from "./city/city.component";
import { AlumniCityComponent } from "./alumni-city/alumni-city.component";
import { UniversityComponent } from "./university/university.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { TheYardComponent } from "./the-yard/the-yard.component";
import { ConnectionsComponent } from "./connections/connections.component";
import { D9TvComponent } from "./d9-tv/d9-tv.component";
import { GreekNewsComponent } from "./greek-news/greek-news.component";
import { VendorsComponent } from "./vendors/vendors.component";
import { ProductsComponent } from "./products/products.component";
import { GreekBusinessComponent } from "./greek-business/greek-business.component";
import { FeaturedListRequestComponent } from "./featured-list-request/featured-list-request.component";
import { ChatsComponent } from "./chats/chats.component";
import { GroupChatsComponent } from "./group-chats/group-chats.component";
import { OrganizationsComponent } from "./organizations/organizations.component";
import { ProfessionComponent } from "./profession/profession.component";
import { GoverningBodyComponent } from "./governing-body/governing-body.component";
import { EmailsComponent } from "./emails/emails.component";
import { StatsAndMatricsComponent } from "./stats-and-matrics/stats-and-matrics.component";
import { UniversityService } from "./_services/university.service";
import { CityService } from "./_services/city.service";
import { AlumniCityService } from "./_services/alumni-city.service";
import { VerificationRequestService } from "./_services/verification-request.service";
import { SettingService } from "./_services/setting.service";
import { SettingComponent } from "./setting/setting.component";
import { BugComponent } from "./bug/bug.component";
import { BugsService } from "./_services/bugs.service";
import { ReportComponent } from "./report/report.component";
import { ReportsService } from "./_services/reports.service";
import { UploaderService } from "./_services/uploader.service";
import { MembershipDuesComponent } from "./membership-dues/membership-dues.component";
import { MembershipDuesService } from "./_services/membership-dues.service";

// *******************************************************************************
//

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,

    // Pages
    LoginComponent,
    DashboardComponent,
    UserAllComponent,
    UserViewComponent,
    VerificationRequestComponent,
    CityComponent,
    AlumniCityComponent,
    UniversityComponent,
    NotificationsComponent,
    TheYardComponent,
    ConnectionsComponent,
    D9TvComponent,
    GreekNewsComponent,
    VendorsComponent,
    ProductsComponent,
    GreekBusinessComponent,
    FeaturedListRequestComponent,
    ChatsComponent,
    GroupChatsComponent,
    OrganizationsComponent,
    ProfessionComponent,
    GoverningBodyComponent,
    EmailsComponent,
    StatsAndMatricsComponent,
    ProfileComponent,
    SettingComponent,
    BugComponent,
    ReportComponent,
    MembershipDuesComponent,
  ],

  imports: [
    BrowserModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,

    // App
    AppRoutingModule,
    LayoutModule,
    ToastrModule.forRoot({
      toastClass: "toast",
    }),
    HttpClientModule,
    FormsModule,
  ],

  providers: [
    Title,
    AppService,
    AuthService,
    UsersService,
    UniversityService,
    CityService,
    AlumniCityService,
    BugsService,
    SettingService,
    VerificationRequestService,
    MembershipDuesService,
    ReportsService,
    DatePipe,
    UploaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
