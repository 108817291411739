import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BugsService {
  private url = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }

  getAll() {
    return this.http.get(this.url + "bug/getAll");
  }
  resolve(id) {
    return this.http.get(this.url + "bug/resolve/" + id);
  }
}
